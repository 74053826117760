
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ticket } from '@/features/om27/types/om27.types';
import { sanitize } from '@/utils/components';

@Component
export default class BlockingTickets extends Vue {
  @Prop({ required: true, type: Array }) tickets!: Ticket[];
  public sanitizeLink = (source:string) => sanitize(source);
  daysOpenLabel(value: number) {
    const isNumber = typeof value === 'number' && isFinite(value);
    return `${isNumber ? value.toFixed(0) : 0} DAYS`;
  }
}
