
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MenuOption } from '@/components/gsk-components/menu/menu.types';
import GPopover from '@/components/gsk-components/popover/GskPopover.vue';

@Component({
  components: {
    GPopover,
  },
})
export default class IconPopoverList extends Vue {
  @Prop({ type: Array, required: true }) options!: MenuOption[];
  @Prop({ type: String, default: 'more_horizontal' }) icon!: string;
  @Prop({ type: Boolean }) mini!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean }) outlined!: boolean;
  @Prop({ type: Boolean }) fab!: boolean;

  isOpen = false;

  togglePopover() {
    this.isOpen = !this.isOpen;
  }

  closePopover() {
    this.isOpen && (this.isOpen = false);
  }

  handleClick(opt: MenuOption) {
    this.$emit('click', opt);
    if ('handler' in opt) {
      opt.handler?.(opt.key, opt.extra);
    }
  }
}
