import { Location } from 'vue-router';
import { getQuery } from '@/utils/routing';
import { Vue } from 'vue-property-decorator';

const token = 'js-expanded';
const qs = (key: number | string) => document.querySelector(`[row-key="${key}"]`)?.classList;
const query = (arg: Location['query']) =>
  getQuery({ $route: { query: arg } }, 'expand', { toArray: true, toNumber: false });

export function highlightExpandedRows(curr: Location['query'], prev: Location['query']): void {
  const c = query(curr);
  const p = query(prev);

  Vue.nextTick(() => {
    p.forEach(key => qs(key)?.remove(token));
    c.forEach(key => qs(key)?.add(token));
  });
}
